/** What's Weird: Basement **/

import React from "react";
import { gsap } from "gsap"
import { preloadImages } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { showText, hideText, positionText, resetAnimationDelay } from "../../helpers"
import Carousel from "../../components/media/carousel";
import SlideshowController from "../../components/media/slideshow-controller";

let globalState, data, slideshowData, autoAdvanceInterval;

class WhatsWeirdPage4 extends React.Component {

  constructor(props) {
    super(props);
    
    //update global state and assets data
    globalState = this.props.location.state;
    data = globalState.assets[4];

    slideshowData = [
      {
        file: globalState.cdnUrl + data.slide1, 
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide2,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide3,
        fileType: 'image',
      },
      {
        file: globalState.cdnUrl + data.slide4, 
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide5, 
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide6, 
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide7, 
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide8, 
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide9, 
        fileType: 'image'
      }
    ]

    //refs
    this.fullscreenBg = null;
  }

  state = {
    textVisible: false,
    currentSlide: 1,
    autoAdvance: 7000
  }

  

  componentDidMount = () => {
    
    setTimeout(()=>{
      this.refs.carouselRef.playVideos();
    }, globalState.transitionDuration);

    //Set AutoAdvance
    this.setAutoAdvance();

    //position text
    positionText(this.refs.text1Ref, "left", "top", globalState.textPosition.leftTop);
    positionText(this.refs.text2Ref, "right", "bottom", globalState.textPosition.rightBottom);
    

    //show text
    gsap.to(this.refs.text1Ref, 0.15, { autoAlpha: 1.0 });
    gsap.to(this.refs.text2Ref, 0.15, { autoAlpha: 1.0 });
    
    //add base track
    globalState.baseAudioRef.updateTrack(1, globalState.cdnUrl + data.soundfile1);

    if(globalState.transitionDirection === "prev") {
      globalState.baseAudioRef.updateTrack(2, globalState.cdnUrl + data.soundfile2);
    } else {
      //update volume of base track
      globalState.baseAudioRef.updateVolume(2, 100);
    }

    
    //Preload assets from next page
    //const preloadData = globalState.assets[3];
    preloadImages([
      globalState.cdnUrl + data.nextTitle,
    ], 
      this.preloadCallback);

    //update the carousel 
    this.refs.carouselRef.updateCurrentSlide(this.state.currentSlide);

  }

  preloadCallback = () =>{
    this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
  }

  componentDidUpdate() {
    this.refs.carouselRef.updateCurrentSlide(this.state.currentSlide);
  }
  

  componentWillUnmount() {
    
    //Kill any tweens
    gsap.killTweensOf(this.refs.text1Ref);
    
    

    //Clear AutoAdvanceInterval
    clearInterval(autoAdvanceInterval);
  }

  

  toggleTextDisplay = (event) => {

    //toggle text visibility
    this.setState({textVisible: !this.state.textVisible});

    //reset the animation delay
    resetAnimationDelay();

    if(this.state.textVisible){
      //show text
      showText(this.refs.text1Ref, "left", "top", globalState.textPosition.leftTop);
      showText(this.refs.text2Ref, "right", "bottom", globalState.textPosition.rightBottom);
      

    } else {
      //hide text
      hideText(this.refs.text1Ref, "left");
      hideText(this.refs.text2Ref, "right");
      
    }
  }

  isTransitioning = () => {
    //this.roadVideoRef.pauseVideo();
    this.refs.carouselRef.pauseVideos();
  }

  //Set AutoAdvance
  setAutoAdvance = () => {
    if (this.state.autoAdvance) {
      autoAdvanceInterval = setInterval(() => {
        let nextSlide = this.state.currentSlide + 1;

        if (nextSlide > slideshowData.length) {
          nextSlide = 1;
        }
        this.setState({ currentSlide: nextSlide });
      }, this.state.autoAdvance);
    }
  }

  //Stop AutoAdvance
  stopAutoAdvance = () => {
    this.setState({ autoAdvance: false });
    clearInterval(autoAdvanceInterval);
  }

  //Slideshow controls
  handleNextClick = () => {
    let nextSlide = this.state.currentSlide + 1;

    if (nextSlide > slideshowData.length) {
      nextSlide = 1;
    }

    this.setState({ currentSlide: nextSlide });
    this.stopAutoAdvance();
  }
  handleBackClick = () => {
    let nextSlide = this.state.currentSlide - 1;

    if (nextSlide < 1) {
      nextSlide = slideshowData.length;
    }

    this.setState({ currentSlide: nextSlide });
    this.stopAutoAdvance();
  }

  updateCurrentSlide = (num) => {

    for (let i = 1; i <= slideshowData.length; i++) {
      this.refs['slide' + i].classList.remove('show');
      if (i === this.state.currentSlide) {
        this.refs['slide' + i].classList.add('show');
      }
    }
  }

  render() {

    return (

      <>

        <link rel="prefetch" href="/remains" />
        
        <PrevNext globalState={globalState} ref="prevNextRef" 
        nextLocation="/remains" prevLocation="/whats-weird/3"
        isTransitioning={this.isTransitioning} />

        <div className="fullpage-wrapper">
          <SEO title="Pine Point - What's Weird" />


          <div className="text-wrapper">
            <div className="text text--left-top" ref="text1Ref">
              <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginLeft: "0", marginTop: "0" }} onClick={this.toggleTextDisplay} alt="The townsite is still there. Roads are surreally intact, 40-year-old crosswalk paint defiantly holds on, but there’s little else. The roads are lined with nothing, as though a suburb was about to be built. A while back someone found a basement that had somehow escaped burial. You pull back an old sheet of plywood, crawl through a hole – and you’re standing in the basement of the Pine Point Hotel. There are cleaning supplies, a room full of receipts and pay stubs, hallways with peeling paint, empty chairs. Then you return to the surface and the hotel disappears." />
            </div>
            <div className="text text--right-bottom" ref="text2Ref">
              <input type="image" src={globalState.cdnUrl + data.text2} style={{ marginRight: "2vw", marginBottom: "120px" }} onClick={this.toggleTextDisplay} alt="I had noticed that Pine Pointers often feel a distinct need to be ‘the last person to…’ At least 4 claim the final drink in the hotel bar. While I was down there, I had a beer, just because. Sorry…" />
              <SlideshowController xPos="220px" yPos="280px" handleNextClick={this.handleNextClick} handleBackClick={this.handleBackClick} state={this.state} totalSlides={slideshowData.length} />
            </div>
          </div>
          <Carousel ref="carouselRef" data={slideshowData} globalState={globalState} fullscreen={true} position={{x:0, y:0}} size="" state={this.state} />
          
        </div>

      </>
    );
  }


};

export default WhatsWeirdPage4
